import React from "react"
import "../css/ImgInvitatie.css"
import eu from '../images/6.jpg'
import mami from '../images/8.jpg'


const CardInvitatie = () => {
    return (

        <section id="invitatie" className="text-center mt-5" >
            <h1>Invitație</h1>
            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4">
                <div className="card p-0 overflow-hidden h-100 shadow">

               
                    <div class="card-body">
                        <div className="text mb-5">
                    <img className="mt-2" id="img_nasi" src={eu} />         
<h4 >
   <span style={{color: 'green', fontSize: '30px'}}>1 Anișor</span>   împlinesc, <br/>
     și vreau să sărbătoresc!
</h4> <br/>
      <h4>
        Haide, nu te mai gândi, <br/>
        Petrecere ca asta nu va mai fi!
        </h4>  
        <img className="mt-1" id="img_nasi" src={mami} />  
        <h4>
        Și nu uitați și de mami! <br/>
        Ea la fel va fi omagiată!
        </h4> <br/>
        <h4>
        Un dublu <span style={{color: 'green', fontSize: '30px'}}>La Mulți Ani</span> <br/>
        Ne veți cânta! <br/><br/>
        Confirmă a ta prezență <br/> 
        Ca să nu-ți pun absență! <br/>
        </h4> 
        <h4>
       Vă așteptăm cu mare drag!<br/>
       Pe data de <span style={{color: 'green', fontSize: '30px'}}>25 Februarie 2023</span> <br/>
       la ora 18:00 <br/>
       acasă la mine unde e cel mai bine!
        </h4> 

<div className="gol"></div>



                        
                           {/* <h4>Istratii </h4>
                            <h4>Vladimir & Ana</h4>

                            <h5 className="animate__animated animate__pulse animate__delay-4s animate__infinite">
                                <svg width="1.5em" height="1.5em" color="red" viewBox="0 0 16 16" class="bi bi-suit-heart-fill"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                                </svg>
                                <br />
                            </h5>
                            <h4>Parascovia Mocanu </h4>

                            <h5>Și cu cei care ne vor sta alături de acum încolo </h5>
                            <img id="img_nasi" src={nasi} />
                            <h4>Ababii</h4>
                            <h4>Nicolae & Rodica</h4>*/}



                        </div>










                      

                        <a href="#forms" className="btn btn-primary">Confirmați Prezența</a>

                    </div>




                </div>




            </div>


        </section>

    )
}

export default CardInvitatie;