import restaurant from "./images/r.jpg";
import img_card from "./images/1.jpg";
import img_nasi from "./images/1.jpg";
import img_bucatar from "./images/1.jpg";
import img_music from "./images/2.jpg";
import imgOmSuflet from "./images/omul.jpg";
import imgOmSufletmb from "./images/omul.jpg";
import imgheader from "./images/happy3.jpg";
import imgheaderm from "./images/happy7.jpg";
import imgheadermiini from "./images/robloxh.jpg";
import imgheadermiinimb from "./images/robloxm.jpg";
import imgconfirmare from "./images/minioni.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        mire: "",
        mireasa: "",
        tata: "Anghelina",
        mama: "Katy",
        copilul: "Micuțul Denis ",
        familia: "fam. Buzu",
        title: "Invitatie Online",
        data: "25 Februarie 2023",
        data_confirmare: "",
        savedata: "Vă invităm!",
        ani: "mami Anghelina",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "cuzuiocalina93@gmail.com", 
       tel: "+4915110624712 ",
       phone: "tel:+4915110624712",
       viber: "viber://chat?number=%2B4915110624712 ",
       whatsapp: "https://wa.me/+4915110624712 ",
       messenger: "https://www.messenger.com/t/alexandruanghelina.buzu",
       tel1: "+40740907712",
       phone1: "tel:+40740907712",
       viber1: "viber://chat?number=%2BXX",
       whatsapp1: "https://wa.me/+40740907712",
       messenger1: "https://www.messenger.com/t/",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Acasă",
            data: "25 februarie 2023, sâmbătă, ora 18:00",
            adress: "Germania 29393, Molkereistr 29 D Groß Oesingen",
            harta: "https://goo.gl/maps/M9vP4T2g85qte4Lf9",
            iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2420.4756949810258!2d10.458370900000002!3d52.6513849!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47afdc16f68f5c4b%3A0x3ac52cca18fbfa1c!2zTW9sa2VyZWlzdHJhw59lIDI5RCwgMjkzOTMgR3Jvw58gT2VzaW5nZW4sINCT0LXRgNC80LDQvdC40Y8!5e0!3m2!1sro!2s!4v1669235286691!5m2!1sro!2s"
           
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Invitație!",
            title2: "VĂ INVITĂM LA ZIUA NOASTRĂ!",
            message: "Pentru că împlinesc 12 ANI, te invit cu mare drag, să sărbătorim împreună, cu muzică, dans și multe suprize!",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img_nasi,
            title1: "",
            title2: "",
            message: '',
        },
    ],
    blogDataBucatar: [
        {
            id: 2,
            img: img_bucatar,
            title1: "Vă invităm alături de cea mai bună bucătăreasă:",
            title2: "Natalia Căpătici Vizitiu",
            message: "Și să gustăm din bucatele delicioase a mânuților de aur!",
        }
    ],
    blogDataMusic: [
        {
            id: 2,
            img: img_music,
            title1: "Sub conducerea muzicală de:",
            title2: "Deejay Vibe",
            message: "Distracția și dansurile sunt asigurate până dimineață!",
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgOmSuflet,
            img1: imgOmSufletmb,
            title: "Ne vom distra pe bune!",
            message: "Alături de cei dragi!",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "12 ANI",
            message: "LA MULȚI ANI!",
        }
    ],
    blogDataConfirmare: [
        {
            imgdesktop: imgconfirmare,
            imgmobil: imgconfirmare,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
        }
    ], 

}

export default data;