import React from "react";

import { ParallaxProvider } from 'react-scroll-parallax';
import '../css/Timer.css';
import '../css/Body.css';
import Timer from "./Timer";
import Parallaximg from "../Parallaximg";

import FavoriteIcon from '@mui/icons-material/Favorite';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';

import { blue, red } from "@mui/material/colors";

import CardLocal from "../cardLocal";

import FormSection from "../FormSection";
import Gallery from "./Gallery";
import BlogSectionNoi from "../blogSectionNoi";
import BlogSectionNasii from "../blogSectionNasii";
import BlogSectionBucatar from "../blogSectionBucatar";
import BlogSectionMusic from "../blogSectionMusic";
import Slide from "./Slide";
import SlideAuto from "./SlideAuto"
import CardInvitatie from "./CardInvitatie";
import data from "../Data";

import music from "../images/music.png"

import muz from "../video/ziua.mp3"
import SlideGallery from "./SlideGallery";

const Home = (props) => {


    return (



        <ParallaxProvider>

            {data.introData.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 500 ? item.imgdesktop : item.imgmobil} height="700px" opacity=".5" >
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 " style={{ maxWidth: '600px', padding: '20px' }}>
                                <h1 className="animate__animated animate__bounceInLeft" style={{ fontSize: '60px', color: 'gold', textShadow: `1px 1px 2px black, 0 0 .5em grey, 0 0 1em darkgreen` }}>{item.copilul}</h1>
                                <h2>&</h2>
                                <h1 className="animate__animated animate__bounceInLeft" style={{ fontSize: '60px', color: 'gold', textShadow: `1px 1px 2px black, 0 0 .5em grey, 0 0 1em darkgreen` }}>{item.ani}</h1> <br />
                                <h4 className="animate__animated animate__bounceInRight animate__delay-2s animate__slower" style={{ fontSize: '36px', color: 'gold' }}>{item.savedata}<br />{item.data}</h4>
                                <Timer />
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}

            <div className="text-center mt-4 pt-4">
                <span className="text-center">
                    <img className="music animate__animated animate__pulse animate__delay-4s animate__infinite" src={music} />
                    <audio src={muz} controls autoPlay loop ></audio>
                </span>
            </div>

            <BlogSectionNasii />


           {/* {data.blogDataDrum.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="400px" opacity=".5">
                        <div className="row text-center align-items-center pt-4 mt-4" style={{ position: 'absolute' }}>
                            <div className="col-12 mt-4 animate__animated animate__rotateIn animate__delay-4s animate__repeat-3 " style={{ maxWidth: '600px', padding: '30px' }}>
                                <h1 style={{ fontSize: '40px', color: 'gold', textShadow: `2px 2px 1px black, 0 0 .5em grey, 0 0 1em darkgreen` }}>{item.title}</h1>
                                <h4 style={{ fontSize: '40px', color: 'gold', fontFamily: 'Harrington', textShadow: `2px 2px 1px black, 0 0 .5em grey, 0 0 1em darkgreen` }}>{item.message}</h4>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}

            <BlogSectionNoi />

            <Gallery />*/}

            <Slide />

            {/* <BlogSectionNasii />*/}


<CardInvitatie/>

         {data.blogDataOmSuflet.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.img : item.img1} height="500px" opacity=".2">
                        <div className="row text-center align-items-center mt-5" style={{ position: 'absolute' }}>
                            <div className="col-12 mt-5" style={{ maxWidth: '600px', padding: '30px' }}>
                                <h1 className="mt-5" style={{ fontSize: '40px', textShadow: `2.5px 2px 1px black, 0 0 1em blue, 0 0 0.2em blue`, color: '#E0FFFF' }}>{item.title}</h1>
                                <h4 className="" style={{fontSize: '25px',textShadow: `1.5px 1px 1px black, 0 0 .5em blue, 0 0 0.5em blue`, color: 'white'}}>{item.message}</h4>
                                <h4 className="" style={{ fontSize: '20px', textShadow: `2.5px 2px 1px black, 0 0 1em blue, 0 0 0.2em blue`, color: '#E0FFFF' }} >{item.nasii}</h4>
                                <h1 style={{ fontSize: '35px', textShadow: `2.5px 2px 1px black, 0 0 1em blue, 0 0 0.2em blue`, color: '#E0FFFF' }}>{item.nasii_nume}</h1>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}

 {/*  


            <BlogSectionBucatar/>

            <BlogSectionMusic />

<SlideGallery/>
            <SlideAuto />*/}

            <CardLocal />



         {/**   {data.blogDataConfirmare.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="400px" opacity=".5">
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 " style={{ maxWidth: '600px', padding: '30px'}}>
                                <h1 style={{ fontSize: '35px',  textShadow: `2.5px 2px 1px black, 0 0 1em blue, 0 0 0.2em blue`, color: '#E0FFFF'}}>Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!</h1>
                                <h4 style={{ fontSize: '25px',  textShadow: `1.5px 1px 1px black, 0 0 1em blue, 0 0 1em blue`, color: '#F5FFFA'}}>P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!</h4>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}
*/} 


            <FormSection />

            {data.introData.map((item) => {
                return (
                    <div>
                        <h4 className="text-center mt-5" >
                            Vă așteptăm cu drag!
                        </h4>

                        <h3 className="text-center mb-5" style={{ fontFamily: 'Dancing Script', fontSize: '30px' }}>
                            {item.familia}
                        </h3>
                    </div>

                )
            })}





        </ParallaxProvider>



    )
}

export default Home;